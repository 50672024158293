import React from "react";
import tw from "twin.macro";
import Decription from "components/features/TwoColDescriptionProducts";

// Import images
import waterTank01 from "assets/water_tank_01.png";
import volumeSensor from "assets/volume_sensor.png";


export default () => {
  const Subheading = tw.span`uppercase tracking-widest font-bold text-primary-500`;

  return (
    <>
      <Decription
        subheading={<Subheading>Produto</Subheading>}
        imageSrc={[waterTank01, volumeSensor]}
        imageBorder={false}
        imageShadow={false}
        imageDecoratorBlob={true}
        description={["\
          Controlador de nível de reservatório a pronta entrega, pode ser utilizado em caixas de água, \
          silos e outros reservatórios em geral. Sistema de armazenamento de informações em 'Nuvem', possibilitando o acesso \
          a informação de qualquer lugar através da internet (celular via aplicativo e computador \
          via Dashboard).\
        "]}
        heading="Controlador e Sensor de nível para reservatórios"
      />
    </>
  );
}
