import React from "react";
import styled from "styled-components";
import tw from "twin.macro";
//eslint-disable-next-line
import { css } from "styled-components/macro";
import Avatar from '@mui/material/Avatar';
import Stack from '@mui/material/Stack';
import { SectionHeading, Subheading as SubheadingBase } from "components/misc/Headings.js";
import { SectionDescription } from "components/misc/Typography.js";

import { ReactComponent as SvgDecoratorBlob3 } from "images/svg-decorator-blob-3.svg";

import productionLineImage from "assets/production_line.png";
import acMotorImage from "assets/motor_ac.png";
import horimetroImage from "assets/horimetro.png";
import temperaturaSensor from "assets/temperature_sensor.png";
import pressureSensor from "assets/pressure_sensor.jpeg";
import nivelSensor from "assets/nivel_sensor.png";
import operatorLoginRfidImage from "assets/operatorLoginRfid.png";
import electricitySensor from "assets/electricity_sensor.png";
import energyAnalyseImage from "assets/energy_analyse.png";

const Container = tw.div`relative`;

const ThreeColumnContainer = styled.div`
  ${tw`flex flex-col items-center md:items-stretch md:flex-row flex-wrap md:justify-center max-w-screen-lg mx-auto py-20 md:py-24`}
`;
const Subheading = tw(SubheadingBase)`mb-4`;
const Heading = tw(SectionHeading)`w-full`;
const Description = tw(SectionDescription)`w-full text-center`;

const VerticalSpacer = tw.div`mt-10 w-full`

const Column = styled.div`
  ${tw`md:w-1/2 lg:w-1/3 max-w-sm`}
`;

const Card = styled.div`
  ${tw`flex flex-col sm:flex-row items-center sm:items-start text-center sm:text-left h-full mx-4 px-2 py-8`}
  .imageContainer {
    ${tw`border text-center rounded-full p-5 flex-shrink-0`}
    img {
      ${tw`w-6 h-6`}
    }
  }

  .textContainer {
    ${tw`sm:ml-4 mt-4 sm:mt-2`}
  }

  .title {
    ${tw`mt-4 tracking-wide font-bold text-2xl leading-none`}
  }

  .description {
    ${tw`mt-1 sm:mt-4 font-medium text-secondary-100 leading-loose`}
  }
`;

const DecoratorBlob = styled(SvgDecoratorBlob3)`
  ${tw`pointer-events-none absolute right-0 bottom-0 w-64 opacity-25 transform translate-x-32 translate-y-48 `}
`;

export default ({
  cards = null,
  heading = "Produtos incríveis para a industria",
  subheading = "Produtos",
  description = "Oferecemos diversas soluções na área de automação industrial, trabalhamos com sensores próprios e sensores de terceiros, tudo interligado com Dashboards personalizados, conforme necessidade do cliente ou interligando com Dashboards existentes."
}) => {
  /*
   * This componets has an array of object denoting the cards defined below. Each object in the cards array can have the key (Change it according to your need, you can also add more objects to have more cards in this feature component) or you can directly pass this using the cards prop:
   *  1) imageSrc - the image shown at the top of the card
   *  2) title - the title of the card
   *  3) description - the description of the card
   *  If a key for a particular card is not provided, a default value is used
   */

  const defaultCards = [
    {
      imageSrc: horimetroImage,
      title: "Horímetro",
      description: ""
    },
    {
      imageSrc: productionLineImage,
      title: "Contadores para linha de produção",
      description: ""
    },
    {
      imageSrc: acMotorImage,
      title: "Detector defeito para motores AC",
      description: ""
    },
    {
      imageSrc: temperaturaSensor,
      title: "Sensor Temperatura",
      description: ""
    },
    {
      imageSrc: nivelSensor,
      title: "Sensor de nível",
      description: ""
    },
    {
      imageSrc: pressureSensor,
      title: "Sensor de pressão",
      description: ""
    },
    {
      imageSrc: electricitySensor,
      title: "Sensor de corrente elétrica",
      description: ""
    },
    {
      imageSrc: energyAnalyseImage,
      title: "Medidor de energia elétrica",
      description: ""
    },
    {
      imageSrc: operatorLoginRfidImage,
      title: "Registro de operador",
      description: ""
    },
  ];

  if (!cards) cards = defaultCards;

  return (
    <Container>
      <ThreeColumnContainer>
        {subheading && <Subheading>{subheading}</Subheading>}
        <Heading>{heading}</Heading>
        {description && <Description>{description}</Description>}
        <VerticalSpacer />
        {cards.map((card, i) => (
          <Column key={i}>
            <Card>
              <Stack direction="row" spacing={2}>
                <Avatar
                  sx={{
                    width: 110,
                    height: 110,
                    border: '1px solid #d3d3d3',
                    boxShadow: 50,
                    shadowOpacity: .5,
                    elevation: 5,
                    boxShadow: 7,
                    borderRadius: '50%'
                  }}
                  alt={card.title}
                  src={card.imageSrc}
                />
              </Stack>
              <span className="textContainer">
                <span className="title">{card.title}</span>
                <p className="description">
                  {card.description}
                </p>
              </span>
            </Card>
          </Column>
        ))}
      </ThreeColumnContainer>
      <DecoratorBlob />
    </Container>
  );
};
