import React from "react";
import tw from "twin.macro";
import { css } from "styled-components/macro"; //eslint-disable-line
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import Hero from "components/hero/TwoColumnWithInput.js";
import Features from "components/features/ThreeColWithSideImage.js";
import MainFeature from "components/features/TwoColWithButton.js";
import MainFeature2 from "components/features/TwoColWithTwoHorizontalFeaturesAndButton.js";
import FeatureWithSteps from "components/features/TwoColWithSteps.js";
import Pricing from "components/pricing/ThreePlans.js";
import Testimonial from "components/testimonials/TwoColumnWithImageAndRating.js";
import FAQ from "components/faqs/SingleCol.js";
import Header from "components/headers/light.js";
import GetStarted from "components/cta/GetStarted";
import Footer from "components/footers/FiveColumnWithBackground.js";
import { FloatingWhatsApp } from 'react-floating-whatsapp'

// Import images
import waterTank01 from "assets/water_tank_01.png";
import temperatureSensorImage from "assets/temperature_flyer.png";
import customDashboardImage from "assets/custom_dashboard.png";
import { Outlet } from "react-router-dom";


export default () => {
  const Subheading = tw.span`uppercase tracking-widest font-bold text-primary-500`;
  const HighlightedText = tw.span`text-primary-500`;

  return (
    <AnimationRevealPage>
      <FloatingWhatsApp
        phoneNumber="31984415787"
        accountName="EMS-A"
        statusMessage="Iremos responder o mais breve possível"
        chatMessage="Olá, seja bem vindo! Como podemos ajudar?"
        placeholder="Digitando..."
        messageDelay={1}
      />
      <Header roundedHeaderButton={true} />
      <Outlet />
      <GetStarted/>
      <Footer />
    </AnimationRevealPage>
  );
}
